<template>
  <div class="componentSty">

    <div class="componentSty_content">
      <el-row style="margin-top: 30px">
        <el-col :span="8" :offset="5">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-form-item label="货主简称" prop="shortname">
              <el-input v-model="addForm.shortname"></el-input>
            </el-form-item>
            <el-form-item label="货主名称" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="货主电话" prop="telephone">
              <el-input v-model="addForm.telephone"></el-input>
            </el-form-item>
            <el-form-item label="货主地址" prop="address">
              <areaSelect v-model="addForm.address"></areaSelect>
            </el-form-item>
            <el-form-item label="详细地址" prop="addrDetail">
              <el-input v-model="addForm.addrDetail"></el-input>
            </el-form-item>
            <el-form-item label="道路运输许可" prop="transportlicencecode">
              <el-input v-model="addForm.transportlicencecode"></el-input>
            </el-form-item>
            <el-form-item label="组织机构代码" prop="orgcode">
              <el-input v-model="addForm.orgcode"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :offset="3">
          <img-upload
            class="img-upload"
            title="企业营业执照"
            v-model="addForm.businesslicense"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            class="img-upload"
            title="运营许可证"
            v-model="addForm.transportlicece"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            
            class="img-upload"
            title="资料信息"
            v-model="addForm.filingvoucher"
        /></el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :offset="10">
          <el-button
            size="small"
            class="buttons"
            type="primary"
            v-if="this.$route.query.id"
            @click="confirmUpdate"
            >确认</el-button
          >
          <el-button
            size="small"
            class="buttons"
            type="primary"
            @click="confirmAdd"
            v-else
            >确认</el-button
          >
          <el-button size="small" class="buttons" @click="goBack"
            >取消</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { codeToText } from "element-china-area-data";
import areaSelect from "@/components/areaSelect.vue";
import ImgUpload from "@/components/Img-upload.vue";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        shortname: "",
        name: "",
        telephone: "",
        address: [],
        addrDetail: "",
        transportlicencecode: "",
        orgcode: "",
        businesslicense: "",
        transportlicece: "",
        filingvoucher: "",
      },
      rules: {
        shortname: [
          {
            required: true,
            message: "请输入货主简称",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入货主",
            trigger: ["blur", "change"],
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入货主电话",
            trigger: ["blur", "change"],
          },
        ],
        address: [
          {
            required: true,
            message: "请选择货主地址",
            trigger: ["blur", "change"],
          },
        ],
        addrDetail: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        transportlicencecode: [
          {
            required: true,
            message: "请输入道路运输许可",
            trigger: ["blur", "change"],
          },
        ],
        orgcode: [
          {
            required: true,
            message: "请输入组织机构代码",
            trigger: ["blur", "change"],
          },
        ],
        businesslicense: [
          {
            required: true,
            message: "请输入组织机构代码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    areaSelect,
    ImgUpload,
  },
  created() {
    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        this.addForm = res.data.data;
        this.addForm.address = this.addForm.addrCodeStr.split(",");
      });
    }
  },
  mounted() {},
  methods: {
    async confirmAdd() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/save",
        "post",
        {
          ...this.addForm,
          addrProvince:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[0]]
              : "",
          addrCity:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[1]]
              : "",
          addrCounty:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[2]]
              : "",
          addrCodeStr: this.addForm.address.join(","),
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }
    },
    async confirmUpdate() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/update",
        "post",
        {
          id: this.$route.query.id,
          ...this.addForm,
          addrProvince:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[0]]
              : "",
          addrCity:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[1]]
              : "",
          addrCounty:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[2]]
              : "",
          addrCodeStr: this.addForm.address.join(","),
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
